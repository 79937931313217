<template>
  <div id="app">
    <v-app>
      <h1>Test PWA Update Again!</h1>
      <v-btn
          v-if="installApp"
          icon
          color="black"
          @click="install">
          <v-icon>mdi-download</v-icon>
      </v-btn>
      <v-snackbar bottom right :value="updateExists" :timeout="-1" color="primary">
        An update is available
        <v-btn text @click="refreshApp">
          Update
        </v-btn>
      </v-snackbar>
      <router-view/>
    </v-app>
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    return {
      installApp: null,
      registration: null,
      updateExists: false,
    }
  },
  created() {

    //Catch install event before prompt and save on 'installApp' variable
    window.addEventListener("beforeinstallprompt", e => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.installApp = e;
    });

    //Called when app installed
    window.addEventListener("appinstalled", () => {
      this.installApp = null;
    });


    //Event listener for update - run once and remove listener
    document.addEventListener('swUpdated', this.updateAvailable, { once: true })

    navigator.serviceWorker.addEventListener('controllerchange', () => {
      // We'll also need to add 'refreshing' to our data originally set to false.
      if (this.refreshing) return
      this.refreshing = true
      // Here the actual reload of the page occurs
      window.location.reload()
    })

  },
  methods: {
    async dismiss() {
      this.installApp = null;
    },
    async install() {
      this.installApp.prompt();
    },
    updateAvailable(event) {
      this.registration = event.detail
      this.updateExists = true
    },
    refreshApp() {
      this.updateExists = false
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) return
      // Send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    }
  }
};
</script>