<template>
    <div>
        <template v-if="isLoading">
            <h3>Loading...</h3>
        </template>
        <template v-else>
            <h3>{{ post.title }}</h3>
            <p>{{ post.content}}</p>
            <router-link to="/">Go back home</router-link>
        </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    name: 'post',
    beforeMount () {
      const { slug } = this.$route.params
      this.getPost({ slug })
    },
    methods: {
        ...mapActions('storePost', ['getPost'])
    },
    computed: {
        ...mapGetters('storePost', ['isLoading', 'post'])
    }
}

</script>