
const state = {
    isLoading: false,
    // its important that we set some defaults for the current post
    // otherwise Vue will complain that properties are `null`
    post: {
      title: '',
      content: '',
      slug: '',
      published: false
    },
    posts: [{
      slug: 'abc',
      title: 'A Sample Blog Post',
      content: 'This is the blog post content',
      published: true
    }, {
      slug: '123',
      title: 'An Unpublished Blog Post',
      content: 'This is the blog post content',
      published: false
    }, {
      slug: 'a1b2',
      title: 'Another Blog Post',
      content: 'This is the blog post content',
      published: true
    }]
}

const mutations = {
    loadingState (state, { isLoading }) {
        state.isLoading = isLoading
      },
      getPost (state, { slug }) {
        state.post = state.posts.find(post => post.slug === slug)
      }
}

const actions = {
    getPost ({ commit }, payload) {
        commit('loadingState', { isLoading: true })
        setTimeout(() => {
          commit('getPost', payload)
          commit('loadingState', { isLoading: false })
        }, 2000)
    }
}

const getters = {
    isLoading (state) {
        return state.isLoading
      },
      post (state) {
        return state.post
      },
      publishedPosts (state) {
        return state.posts.filter(post => post.published)
      },
      publishedPostsCount (state, getters) {
        return getters.publishedPosts.length
      }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}